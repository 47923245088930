<template>
    <v-container>
        <div style="margin: 60px 0;"></div>
        <CFS />
    </v-container>
</template>

<script>
import CFS from '@/components/cfs/Index'

export default {
    components: {
        CFS
    }
}
</script>